<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import useHttp from '@/comp-functions/useHttp'
import Swal from "sweetalert2";

export default {
  page: {
    title: "User List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
    setup () {


    let tableColumns = []
    let userLogin = JSON.parse(localStorage.getItem('user'))
    if(userLogin.usertype === 'UTYINL'){
      tableColumns = [
        { key: "usr_fullname", label: 'Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "email", label: 'Email', thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "usertype", label: "User Type", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "userrole", label: "User Role", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "customer_id", label: "Customer", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "vendor", label: "Vendor", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "action",label: "#", tdClass: "text-center" }
      ]
    }else{
      tableColumns = [
        { key: "usr_fullname", label: 'Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "email", label: 'Email', thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "usertype", label: "User Type", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "customer_id", label: "Customer", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "action",label: "#", tdClass: "text-center" }
      ]
    }

     const { 
      isBusy,
      itemLists,
      perPage,
      perPageDefault,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: 'master/users' })

    return {
      tableColumns, 
      isBusy,
      itemLists,
      perPage,
      perPageDefault,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
  data() {
    return {
      title: "User List",
      items: [],
      filterOn: []
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    },
    async dataFalse(id) {
      const { $put } = useHttp()
      $put({
         url: 'master/user/nonactive',
        data: {id}
      })
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: 'User status has been delete',
        showConfirmButton: true,
        timer: 2600,
        timerProgressBar: true
      });
      this.searchQuery = ''
      await this.fetchLists('master/users')
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-button :to="{ name: 'appsmd-user-add'}" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> Add New
            </b-button>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
             <div class="table-responsive mb-0">
              <b-table
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
               <template v-slot:cell(action)="data">
                  <b-button class="ml-2"  variant="light" :to="{ name: 'appsmd-user-edit', params: {user_id: data.item.id}}">
                      <i class="ri-edit-2-line"></i>
                  </b-button>
                  <b-button class="ml-2"  variant="danger" @click.prevent="dataFalse(data.item.id)">
                      <i class="mdi mdi-trash-can"></i>
                  </b-button>
                </template>

              <template #cell(usertype)="data">
                  {{ data.item.userType.descr_en }}
                </template>
              <!-- <template #cell(userrole)="data">
                  {{ data.item.userRole.url_name }}
                </template> -->
                <template #cell(customer_id)="data">
                  <span v-if="typeof data.item.customer !== 'undefined'">{{ data.item.customer.name }}</span>
                </template>
                <template #cell(vendor)="data">
                  <span v-if="typeof data.item.vendor !== 'undefined'">{{ data.item.vendor.name }}</span>
                </template>
              </b-table>
            </div>
             <div class="row">
                <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>

               <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalList"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>